<template>
  <div :style="{ width: src ? '100%' : '100%' }">
    <v-btn
      :disabled="disabled"
      v-if="!src"
      x-small
      icon
      outlined
      @click="$emit('open')"
      class="mb-2"
    >
      <v-icon> {{ icon }} </v-icon>
    </v-btn>
    <template>
      <slot name="preview"></slot>
    </template>

    <v-dialog
      v-model="show_dialog"
      width="800"
      max-width="83.333%"
      @click:outside="$emit('close')"
    >
      <v-card>
        <v-card-title>
          <span>Sisipkan {{ type }}</span>
          <v-spacer></v-spacer>
          <v-icon @click="$emit('close')">$close</v-icon>
        </v-card-title>
        <v-col class="px-6 py-0">
          <slot name="tabs"></slot>
        </v-col>

        <v-divider></v-divider>
        <div class="d-md-flex" style="height: 500px">
          <v-col class="fill-height background pa-0 overflow-auto">
            <slot name="tab-items"></slot>
          </v-col>

          <!-- FORNEXT: make expand transition -->
          <div
            class="col-md-4 col-12 pa-0"
            v-if="selected && (selected.url || selected.public_url)"
          >
            <v-col class="white pa-0 fill-height-md overflow-auto">
              <div class="d-md-flex">
                <v-divider class="hidden-md-and-up"></v-divider>
                <v-divider class="hidden-sm-only" vertical></v-divider>
                <div class="pa-6">
                  <div class="title">Information media</div>

                  <v-row>
                    <v-col>
                      <div class="subtitle-2 mb-2">Title</div>
                      <v-text-field
                        :readonly="readonly"
                        filled
                        outlined
                        hide-details="auto"
                        v-model="selected.title"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <div class="subtitle-2 mb-2">Caption</div>
                      <v-text-field
                        :readonly="readonly"
                        filled
                        outlined
                        hide-details="auto"
                        v-model="selected.caption"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <div class="subtitle-2 mb-2">Alt</div>
                      <v-text-field
                        :readonly="readonly"
                        filled
                        outlined
                        hide-details="auto"
                        v-model="selected.alt"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <div class="subtitle-2 mb-2">Description</div>
                      <v-textarea
                        :readonly="readonly"
                        outlined
                        filled
                        hide-details="auto"
                        rows="3"
                        no-resize
                        v-model="selected.description"
                      ></v-textarea>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col v-if="!isFormat" cols="12">
                      <div class="red--text small">
                        Please select {{ type }} file format
                      </div>
                    </v-col>
                    <v-col v-if="error" cols="12">
                      <div class="red--text small">
                        {{ error }}
                      </div>
                    </v-col>
                    <v-col>
                      <v-btn
                        color="primary"
                        elevation="0"
                        @click="$emit('set', selected)"
                        :loading="saving"
                        :disabled="isDisabled"
                        >Lanjut</v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "attachment-layout",
  props: {
    type: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    src: { type: String, required: true },
    tab: { type: Number, required: true },
    selected: { type: Object, default: () => ({}) },
    readonly: { type: Boolean, default: false },
    show: { type: Boolean, default: false },
    saving: { type: Boolean, default: false },
    error: { type: String, default: "" },
  },
  data: () => {
    return {
      show_dialog: false,
    };
  },
  created() {
    this.show_dialog = this.show;
  },
  computed: {
    isDisabled() {
      return this.disabled || !this.isFormat;
    },
    icon() {
      switch (this.type) {
        case "audio":
          return "$music";
        case "image":
          return "$image";
        default:
          return "$info";
      }
    },
    isFormat() {
    // console.log('layout',this.selected)
      // console.log(this.src.media)
      return (
        (this.selected.file && this.selected.file.type.includes(this.type))
        || (this.selected.type && this.selected.type == this.type)
        || (this.src.media && this.src.media.type == this.type)
      );
    },
  },
  methods: {
    async set() {
      // console.log(this.selected);
      await this.$emit("set", this.selected.public_url);

    },
  },
  watch: {
    show(val) {
      this.show_dialog = val;
    },
    show_dialog(val) {
      this.$emit("update:show", val);
      // console.log('layout',this.src)

    },
  },
};
</script>